import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateClient } from '../templates';
import { Contact } from '../components';
import { Heading, Paragraph, Link } from '@arturpol/style-guide/src/components';
import formData from '../data/contact';
export const meta = {
  title: 'Contact',
  type: 'client',
  hint: 'Contact us',
  order: 256,
  isPartiallyActive: false
};
export const template = Contact;
export const details = <>
        <Heading importance={4} size={1} mdxType="Heading">Looking for an email address?</Heading>
        <Paragraph mdxType="Paragraph">Write to us: <Link url="mailto:hi@systemsforhumans.com" mdxType="Link">hi@systemsforhumans.com</Link> or call: +48 601 066 287 (Mon-Fri 10 am-6 pm CET).</Paragraph>
    </>;
export const form = formData;
export const _frontmatter = {};
const layoutProps = {
  meta,
  template,
  details,
  form,
  _frontmatter
};
const MDXLayout = TemplateClient;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      